<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="稽查员姓名" prop="userName">
                <el-input
                  v-model="formInline.userName"
                  maxlength="20"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')" prop="operationId">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                  @change="getOperationInfo"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.On_duty_status')" prop="">
                <el-select
                  v-model="formInline.pdaManagerState"
                  placeholder="请选择"
                  filterable
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="在职" value="1"></el-option>
                  <el-option label="离职" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.date')" prop="dateType" class="time_item">
                <el-select
                  v-model.trim="formInline.dateType"
                  placeholder="请选择"
                  style="width: 72px; margin: 0 7px 0 0"
                  @change="timeTypeChange"
                >
                  <el-option label="日" value="1"></el-option>
                  <el-option label="月" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  class="date_picker"
                  v-model="searchTime"
                  @change="searchTimeChange"
                  :clearable="false"
                  :type="timeType"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                @click="searchData"
                >查询
                <!-- v-if='$route.meta.authority.button.query' -->
              </el-button>
              <el-button
                type="info"
                icon="el-icon-delete"
                @click="clear"
                :loading="loading"
                >清空
                <!-- v-if='$route.meta.authority.button.query' -->
              </el-button>
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.button.batchExport"
          ></div>
          <div
            class="col_box h44"
            v-if="$route.meta.authority.button.batchExport"
          >
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button
                type="primary"
                v-if="$route.meta.authority.button.batchExport"
                icon="el-icon-upload2"
                @click="
                  pageNum = 1;
                  exportExcelReport();
                "
                :loading="loading"
                >导出
              </el-button>
            </div>
          </div>

          <!-- v-if="$route.meta.authority.button.batchExport" -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-if="total != 0">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
//  import myComponent from '@/components/autocomplete/myautoComponent'

export default {
  name: "parkIncomeForm",
  data() {
    // const startTime = new Date();
    // startTime.setTime(startTime.getTime() - 3600 * 1000 * 24);
    // // startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    // const endTime = new Date();
    // endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    let today = new Date(new Date().getTime() - 86400000);
    let yesterday = new Date(new Date().getTime() - 86400000 * 32);
    return {
      tenantList: [],
      timeType: "daterange",
      searchTime: [yesterday, today],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      pageNum: 1,
      pageSize: 15,
      total: 10,
      // startTime: startTime,
      // endTime: endTime,
      parkList: [],
      tableData: [],
      tableCols: [
        {
          prop: "pdaManagerName",
          label: this.$t("list.Inspector_name"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "",
        },
        {
          prop: "pdaManagerState",
          label: this.$t("list.On_duty_status"),
          width: "",
          //          formatter: (row) => {
          //             if (row.workState == 0) {
          //               return '在职'
          //             } else if (row.workState == 1) {
          //               return '离职'
          //              } else {
          //               return ''
          //             }
          //           }
        },
        {
          prop: "checkReport",
          label: this.$t("list.Audit_report"),
          width: "",
          // formatter: (row) => {
          //   if (row.weChat) {
          //     return Number((row.weChat)).toFixed(2)
          //   } else {
          //     return '0.00'
          //   }
          // }
        },
        {
          prop: "parkCheck",
          label: this.$t("list.Inspect_roads"),
          width: "",
        },
      ],
      searchType: "historyData",
      loading: false,
      type1: "c3",
      type2: "c4",
      formInline: {
        userName: "",
        operationId: "",
        dateType: "1",
        startTime: "",
        endTime: "",
        pdaManagerState: "",
      },
    };
  },
  methods: {
    searchTimeChange() {
      let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";
      this.formInline.startTime = dateFormat(this.searchTime[0], tmp);
      this.formInline.endTime = dateFormat(this.searchTime[1], tmp);
    },
    // 所属运营商选择
    getOperationInfo(val) {
      this.formInline.operationId = val;
    },
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    timeTypeChange() {
      if (this.formInline.dateType === "2") {
        this.timeType = "monthrange";
        let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";
        const startTime = new Date();
        const endTime = new Date();
        startTime.setTime(startTime.getTime());
        endTime.setTime(endTime.getTime());
        this.searchTime[0] = dateFormat(startTime, tmp);
        this.searchTime[1] = dateFormat(endTime, tmp);
        this.formInline.startTime = this.searchTime[0];
        this.formInline.endTime = this.searchTime[1];
      } else {
        this.timeType = "daterange";
        let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";
        const startTime = new Date();
        const endTime = new Date();
        startTime.setTime(startTime.getTime() - 1 * 24 * 60 * 60 * 1000);
        endTime.setTime(endTime.getTime() - 1 * 24 * 60 * 60 * 1000);
        this.searchTime[0] = dateFormat(startTime, tmp);
        this.searchTime[1] = dateFormat(endTime, tmp);
        this.formInline.startTime = this.searchTime[0];
        this.formInline.endTime = this.searchTime[1];
      }
    },
    // 清除
    clear() {
      // const startTime = new Date();
      // const endTime = new Date();
      // startTime.setTime(startTime.getTime() - 3600 * 1000 * 24);
      // // startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
      // endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
      // this.startTime = startTime;
      // this.endTime = endTime;
      this.timeType = "daterange";
      let today = new Date(new Date().getTime() - 86400000);
      let yesterday = new Date(new Date().getTime() - 86400000);
      this.searchTime = [yesterday, today];
      // let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";
      // this.formInline.startTime = dateFormat(this.searchTime[0], tmp);
      // this.formInline.endTime = dateFormat(this.searchTime[1], tmp);
      this.formInline = {
        userName: "",
        operationId: "",
        dateType: "1",
        startTime: dateFormat(this.searchTime[0], "yyyy-MM-dd"),
        endTime: dateFormat(this.searchTime[1], "yyyy-MM-dd"),
      };
      console.log(this.formInline);
    },
    // 日期切换
    getTimeNow() {
      const startTime = new Date();
      const endTime = new Date();
      if (this.formInline.showDate == "1") {
        startTime.setTime(startTime.getTime() - 3600 * 1000 * 24);
        // startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
        endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
      } else {
        // startTime.setTime(startTime.getTime() - 365 * 24 * 3600 * 1000);
        startTime.setTime(startTime.getTime());
        // startTime.setMonth(startTime.getMonth() - 11)
        endTime.setTime(endTime.getTime());
      }
      this.startTime = startTime;
      this.endTime = endTime;
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 停车场名称下拉事件
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    // 时间判断
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        if (this.formInline.dateType === "1") {
          let startTime = dateFormat(
            new Date(this.formInline.startTime),
            "yyyy-MM-dd"
          );
          let endTime = dateFormat(
            new Date(this.formInline.endTime),
            "yyyy-MM-dd"
          );
          this.formInline.startTime = startTime;
          this.formInline.endTime = endTime;
          let data1 = Date.parse(startTime.replace(/-/g, "/"));
          let data2 = Date.parse(endTime.replace(/-/g, "/"));
          let datadiff = data2 - data1;
          let time = 30 * 24 * 60 * 60 * 1000;
          if (datadiff > time) {
            this.$alert("搜索时间不能超过31天", "提示");
            return false;
          }
        } else if (this.formInline.dateType === "2") {
          let startTime = dateFormat(
            new Date(this.formInline.startTime),
            "yyyy-MM"
          );
          let endTime = dateFormat(
            new Date(this.formInline.endTime),
            "yyyy-MM"
          );
          this.formInline.startTime = startTime;
          this.formInline.endTime = endTime;
          var data1 = Date.parse(startTime.replace(/-/g, "/"));
          var data2 = Date.parse(endTime.replace(/-/g, "/"));
          var datadiff = data2 - data1;
          let time = 354 * 24 * 60 * 60 * 1000;
          if (datadiff > time) {
            this.$alert("搜索时间不能超过12个月", "提示");
            return false;
          }
        }
        if (this.formInline.startTime > this.formInline.endTime) {
          this.$alert("结束时间不能小于开始时间", "提示");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("开始/结束时间不能为空", "提示");
        return false;
      }
    },
    // 搜索
    searchData() {
      //        if (this.formInline.parkId != '') {
      //          this.$refs.parkInput.setShowVal(this.formInline.parkName)
      //          this.getReport();
      //        } else {
      //          this.$refs.parkInput.setShowVal('')
      //          this.getReport();
      //        }
      let flag = this.showLog();
      if (flag) {
        this.$axios
          .get("/acb/2.0/pdaReport/list", {
            data: {
              page: this.pageNum,
              pageSize: this.pageSize,
              userName: this.formInline.userName,
              operationId: this.formInline.operationId,
              dateType: this.formInline.dateType, // 必填，day/month
              startDate: this.formInline.startTime, // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
              endDate: this.formInline.endTime, // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
              pdaManagerState: this.formInline.pdaManagerState,
            },
          })
          .then((res) => {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          });
      }
    },
    // 稽查员工作统计 导出
    exportExcelReport() {
      let flag = this.showLog();
      if (flag) {
        exportExcelNew("/acb/2.0/pdaReport/export", {
          dateType: this.formInline.dateType,
          startDate: this.formInline.startTime,
          endDate: this.formInline.endTime,
          operationId: this.formInline.operationId,
          userName: this.formInline.userName,
        });
      }
    },
  },
  components: {
    //      myComponent,
  },
  beforeDestroy() {},
  created() {
    this.timeTypeChange();
    this.getTenantList();
  },
  computed: {},
  mounted() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
::v-deep .el-form-item.time_item {
  min-width: 0;

  .el-input__inner {
    min-width: 72px;
  }
}

.el-icon-date-custom {
  position: relative;
  left: -26px;
}

.pagerWrapper {
  text-align: right;
  margin-top: 18px;
  font-size: 12px;
}

.date_picker {
  width: 324px;
}

.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // margin-bottom: 20px;
  }

  .list {
    margin-top: 20px;

    .el-col {
      padding-bottom: 18px;
      height: 320px;

      .grid-content {
        padding-top: 15px;
        height: 100%;
      }

      h2 {
        font-size: 18px;
        text-align: center;
      }
    }
  }
}
</style>
